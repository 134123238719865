import React from 'react'
import LogoSrc from '../../../assets/images/logo.jpg'

function BusinessBrag() {
    return (
        <div className='brag'>
            <div className='br-title'>Badge</div>
            <div className='br-subtitle'>Some slogan here</div>
            <div className='br-graphic' style={{backgroundImage: `url(${LogoSrc})`}} />
        </div>
    )
}

export default BusinessBrag