import React from 'react'
import Carousel from 'react-bootstrap/Carousel';

export default function Bragger() {
  const items = ['https://i.ibb.co/DRRzfJN/PHOTO-2023-07-03-07-20-31-2.jpg', 'https://i.ibb.co/M1zmBhx/PHOTO-2023-07-03-07-20-31.jpg', 'https://i.ibb.co/ZGw6x1M/PHOTO-2023-07-03-07-15-28-2.jpg'];
  return (
    <div className='bragger'>
      <Carousel>
        {items.map(a => (
          <Carousel.Item style={{padding: 10}}>
            <div style={{backgroundImage: `url(${a})`}} className='caro-item' />
          </Carousel.Item>))}
      </Carousel>
    </div>
  )
}
