import React from 'react'
import { Footer, Header } from '../../components'
import Hero from './components/Hero'
import Features from './components/Features'
import ContactSection from './components/ContactSection'
import Bragger from './components/Bragger'
import TeamMembers from './components/TeamMembers'
import BusinessBrag from './components/BusinessBrag'

function WelcomePage() {
  return (
    <div className='welcome'>
        <Header />
        <Hero />
        <Features />
        <Bragger />
        <TeamMembers />
        <ContactSection />
        <BusinessBrag />
        <Footer />
    </div>
  )
}

export default WelcomePage