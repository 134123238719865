import React from 'react'
import Button from '../button'
import { IconInstagram, IconTwitter } from '../icons'
import Link from '../link'

const iconSize = 15;

function Footer() {
    function goTo(link: string) {
        window.open(link, '_blank')
    }

    return (
        <div className='footer'>
            <div className='ft-content'>
                <Link url='/about' text='About' />
                <div className='socials flex'>
                    <Button onClick={() => goTo('https://twitter.com/BrightSporting')} fancy icon={<IconTwitter size={iconSize} />}  />
                    <Button onClick={() => goTo('https://instagram.com/officialbrightelitesc')} fancy icon={<IconInstagram size={iconSize} />} />
                </div>
            </div>
        </div>
    )
}

export default Footer