import React from 'react'
import { WelcomeGraphicAnimation, IconBall } from '../../../components';
import Avatar from '../../../components/avatar';
import ListItem from '../../../components/list';
import man from '../../../assets/images/profiles/man.jpeg';
import Link from '../../../components/link';
import { LogoOnly } from '../../../components/logo/Logo';

function Hero() {
  const d = (id: string) => {
    const d = document.getElementById(id);
    if (d) d.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <div className='hero-container'>
      <div className='hero-content'>
        <div className='mobile-stuff'>
          <a href='/'>
            <span className='mobile-only'><LogoOnly /></span>
          </a>
          <div className='center'>
          <Link activeClassName='link-active' text='Home' url='/' />
          <Link exact onClick={() => d('team')} url='/#team' text='Team' />
          <Link onClick={() => d('contact')} url='/#contact' text='Contact us' />
        </div>
        </div>
        <div className='hero-left'>
          <div className='hero-label'>
            <div>Professional Football</div>
            <div className='icon'><IconBall color='var(--black)' /></div>
          </div>
          <div className='hero-title'>
            We <span>attack</span> &amp; <span> win</span> any competition!
          </div>
          <div className='hero-subtitle'>
            Welcome to Bright Elite FC official website. Here, you have up to date information with our wins, matches, news, players etc.
          </div>
          <div className='hero-comments'>
            <ListItem className='testim' left={<Avatar url={man} />}
              title="Owner's comment"
              subtitle="We are looking to grow this team to become even larger. Bright Elite SC is growing at every level. We win, even when there is a loss." />
            <div className='cm-divider'></div>
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-graphic'>
            <WelcomeGraphicAnimation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero