import React from 'react'
import LogoSrc from '../../assets/images/logo.jpg'


interface Props {
  size?: number;
  showText?: boolean;
  showGraphic?: boolean;
}

function Logo({ showGraphic = true, showText, size = 60 }: Props) {
  return (
    <div className='logo'>
      {showGraphic && <img style={{ height: 'auto', width: '40px' }} src={LogoSrc} alt='' />}
      {showText && <div style={{ fontSize: size / 2.5, marginLeft: 8 }} className='logo-text'>Bright Elite SC</div>}
    </div>
  )
}

export function LogoOnly() {
  return (
    <div className='logo'>
      <img style={{  width: '70px' }} src={LogoSrc} alt='' />
    </div>
  )
}

export default Logo