import React, { useEffect, useState } from 'react'
import { Logo } from '../logo'
import Link from '../link'
import Button from '../button'
import { IconDarkTheme, IconLightTheme } from '../icons'
import { getStoreItem, saveStoreItem } from '../../utils'
import { themeKey } from '../../utils/exports/Keys'

function Header() {
    const theme = getStoreItem(themeKey) || "dark";
    const [isDarkTheme, setIsDarkTheme] = useState<boolean>(true)
    const d = (id: string) => {
        const d = document.getElementById(id);
        if (d) d.scrollIntoView({ behavior: "smooth" })
    }
    const toggleTheme = () => {
        const body = document.body;
        if (isDarkTheme) {
            setIsDarkTheme(false);
            saveStoreItem(themeKey, 'light')
            body.setAttribute("class", "light")
            return
        }
        setIsDarkTheme(true);
        saveStoreItem(themeKey, 'dark')
        body.setAttribute("class", "dark")
    }

    useEffect(() => {
        setIsDarkTheme(theme === "dark")
    }, [theme])


    return (
        <div className='header mobile-link'>
            <div className='hd-bucket'>
                <div className='hd-left'>
                    <a href='/'>
                        <span className='mobile-link'><Logo showText size={70} /></span>
                    </a>
                    <Button onClick={toggleTheme} style={{ marginLeft: 12 }} icon={isDarkTheme ? <IconLightTheme /> : <IconDarkTheme />} />
                </div>
                <div className='center hd-right'>
                    <div className='center hd-links'>
                        <Link className='mobile-link' activeClassName='link-active' text='Home' url='/' />
                        <Link className='mobile-link' exact onClick={() => d('team')} url='/#team' text='Team' />
                        <Link className='mobile-link' onClick={() => d('contact')} url='/#contact' text='Contact us' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header