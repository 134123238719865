import React from 'react'
import { ReactComponent as Wave } from '../../../assets/svgs/wave.svg';
import ChuttAppLogo from '../../../assets/images/chuttapp.png'
import IPLogo from '../../../assets/images/ip.png'
import oarkLogo from '../../../assets/images/oarkx.png'


interface FeatureProps {
    icon: any;
    title: string;
}

function Features() {
    const data = [
        {
            icon: IPLogo,
            title: 'Intelligence Pool',
        },
        {
            icon: ChuttAppLogo,
            title: 'ChuttApp',
        },
        {
            icon: oarkLogo,
            title: 'OARKX Companies',
        },
    ];
    return (
        <div className='section'>
            <div className='wave flip'>
                <div><Wave /></div>
            </div>
            <div id='services' className='features-wrapper'>
                <div className='fancy-feature-hero bottom'>
                    <div className='fancy-card'>
                        <h1 className='fn-ft-title'>Our <span>Sponsors</span></h1>
                        <div className='fn-ft-subtitle'>We proud of these wonderful sponsors that keep Bright Elite FC going!</div>
                    </div>
                </div>
                <div className='fancy-feature-connector'>
                    <div className='fancy-feature-line'></div>
                    <div className='fancy-feature-dot'></div>
                </div>
                <div className='features'>
                    {data?.map((a, i) => <Feature key={i} icon={a.icon} title={a.title} />)}
                </div>
            </div>
        </div>
    )
}

function Feature({ icon, title }: FeatureProps) {
    return (
        <div className='feature'>
            <div className='ft-graphic'>
                <img src={icon} alt={title} />
            </div>
            <div className='ft-title'>{title}</div>
            {/* <div className='ft-subtitle'>{subtitle}</div> */}
        </div>
    )
}


export default Features