import React from 'react'

export default function TeamMembers() {
    const items = [
        {
            name: "Owusu Foster",
            imgURL: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
            position: "Forward"
        },
        {
            name: "Owusu Foster",
            imgURL: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
            position: "Forward"
        },
        {
            name: "Owusu Foster",
            imgURL: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
            position: "Forward"
        },
        {
            name: "Owusu Foster",
            imgURL: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
            position: "Forward"
        },
        {
            name: "Owusu Foster",
            imgURL: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
            position: "Forward"
        },
        {
            name: "Owusu Foster",
            imgURL: 'https://www.unsw.edu.au/content/dam/images/medicine-health/population-health/headshots/2022-07-med-pop-health/2022-08-tewodros-profile.cropimg.width=335.crop=square.jpg',
            position: "Forward"
        },
    ];
    return (
        <div id='team' className='section' style={{marginTop: 50}}>
            <div className='features-wrapper'>
                <div className='title'>The Team</div>
                <div className='team'>
                    {items.map(a => (
                        <div className='tm-item'>
                            <div className='tn-img-content'>
                            <div className='tm-img' style={{backgroundImage: `url(${a.imgURL})`}} />
                            </div>
                            <div className='tm-cont'>
                                <div className='tm-title'>{a.name}</div>
                                <div className='tm-subtitle'>{a.position}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
